<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('blockedtimes')"
        @delete="helperDeleteItem(`/blockedtimes/${$route.params.id}`, null, 'blockedtimes')"
        @save="updateItem"
        @refresh="getItem"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
          <div class="mb-2">
            <b-form-checkbox v-model="currentItem.is_active" value="1" name="status" class="custom-control-success">
              {{ $t("Active") }}
            </b-form-checkbox>
          </div>
          <hr/>
          <b-row>
            <b-col md="6" xl="6">
              <validation-provider #default="{ errors }" :name="$t('Start date')" rules="required" vid="start_date">
                <b-form-group :label="$t('Start date')">
                  <b-form-datepicker id="deadline" v-model="currentItem.start_date" :placeholder="$t('Start date')"
                                     :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                     v-bind="labels[locale] || {}" :state="getValidationState(errors)"
                                     :min="new Date().toISOString().substr(0, 10)"
                                     :max="(currentItem.end_date ? currentItem.end_date : null)"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('start_date')" class="text-danger">{{
                      $t(validation.start_date[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" :name="$t('Start time')" vid="start_time" rules="required">
                <b-form-group :label="$t('Start Time')">
                  <b-form-input :raw="false" v-mask="'##:##'" placeholder="hh:mm" v-model="currentItem.start_time"
                                :state="getValidationState(errors)" class="form-control"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('start_time')" class="text-danger">{{
                      $t(validation.start_time[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" xl="6">
              <validation-provider #default="{ errors }" rules="required|dateChecker:@start_date" :name="$t('End date')"
                                   vid="end_date">
                <b-form-group :label="$t('End date')">
                  <b-form-datepicker v-model="currentItem.end_date" :placeholder="$t('End date')"
                                     :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                     v-bind="labels[locale] || {}"
                                     :min="(currentItem.start_date ? currentItem.start_date : new Date().toISOString().substr(0, 10))"
                                     :state="getValidationState(errors)"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('end_date')" class="text-danger">{{
                      $t(validation.end_date[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" :name="$t('End time')" vid="end_time"
                                   rules="required|timeChecker:@start_time,@start_date,@end_date">
                <b-form-group :label="$t('End Time')">
                  <b-form-input :raw="false" v-mask="'##:##'" placeholder="hh:mm" v-model="currentItem.end_time"
                                :state="getValidationState(errors)" class="form-control"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="Object.keys(validation).includes('end_time')" class="text-danger">{{
                      $t(validation.end_time[0])
                    }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <b-card>
      <b-button class="mt-2" variant="success" type="submit" @click.prevent="onSubmit">
        {{ $t("Save") }}
      </b-button>
    </b-card>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormTimepicker,
  BFormSelect,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import Cleave from "vue-cleave-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

import {required, alphaNum, email} from "@validations";

import BCardActions from '@core/components/b-card-actions/BCardActions'
import {extend} from 'vee-validate';
import moment from 'moment'
import {setDatePickerTranslation} from "@core/mixins/datePickerLocales";

extend('dateChecker', {
  params: ['target'],
  validate(value, {target}) {
    let start_date = target;
    let end_date = value;
    return moment(start_date).isSameOrBefore(end_date);
  },
  message: 'End date must be at least the same date as the start date'
});


extend('timeChecker', {
  params: ['start_time', 'start_date', 'end_date'],
  validate(value, {start_time, start_date, end_date}) {
    let start = start_date + ' ' + start_time;
    let end = end_date + ' ' + value;

    return moment(start).isSameOrBefore(end);
  },
  message: 'End date must be at least the same date as the start date'
});


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    BFormTimepicker,
    BFormSelect,
    Cleave,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },
  mixins: [setDatePickerTranslation],
  data() {
    return {
      currentItem: {
        is_active: false,
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
      },

      timeOption: {
        start_time: {
          time: true,
          timePattern: ["h", "m"],
        },
        end_time: {
          time: true,
          timePattern: ["h", "m"],
        },
      },
      loaded: true,
      validation: {},
      validate: false,
    };
  },


  mounted() {
    this.helperGetItem(`/blockedtimes/${this.$route.params.id}`);
  },

  methods: {
    getItem() {
      this.helperGetItem(`/blockedtimes/${this.$route.params.id}`)
    },

    updateItem() {
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperUpdateItem(
                  `/blockedtimes/${this.$route.params.id}`,
                  this.currentItem
              )
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },
    deleteItem() {
      this.helperDeleteItem(
          `/blockedtimes/${this.$route.params.id}`,
          null,
          "blockedtimes"
      );
    },
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },

    onSubmit() {
      this.updateItem();
    },
  },
};
</script>
